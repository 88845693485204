import { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    window.location.href = "https://www.musicglue.com/hurstfolk-1/";
    window.location.href = "https://musicglue.com/hurstfolk-1/";
  }, []);

  return null;  // Return null since we don't need to render anything
}

export default App;
